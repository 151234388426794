<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>网授课程</el-breadcrumb-item>
      <el-breadcrumb-item>试卷题目</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card>
      <el-row type="flex" align="top">
        <el-col :span="9">
          <!--搜索与添加-->
          <el-form :inline="true" class="searchDiv">
            <el-form-item label="试卷ID">
              <el-input
                  clearable
                  placeholder="搜索试卷ID"
                  v-model="shijuan_id"
              ></el-input>
            </el-form-item>
            <el-form-item label="题目类型">
              <el-select v-model="tm_type" clearable placeholder="请选择">
                <el-option
                    v-for="(item2, index2) in validArr"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

        </el-col>
        <el-col :span="15" style="display: flex;justify-content: space-between;">

          <el-button size="mini" round type="success" @click="onSubmit"
          >搜索
          </el-button
          >

          <el-button size="mini" round type="primary" @click="onAdd"
          >新增
          </el-button
          >


          <el-button size="mini" round type="success" @click="onWrite"
          >计算分值数量
          </el-button
          >
          <el-button size="mini" round type="warning" @click="onDownMuoBan"
          >下载导入模板
          </el-button
          >
          <el-upload
              ref="refupload"
              class="upload-demo"
              accept=".xlsx"
              multiple
              :limit="1"
              :action="$baseUrl+'KaoShiTiMu/upXlsx'"
              :on-exceed="onExceed"
              :on-success="onSuccess"
              :on-error="onError"
              :before-upload="beforeUpload"
              :on-change="onChange"
              :data="$getUserData()"
          >
            <el-button size="mini" round type="info" @click="onUpTiMu"
            >导入题目xlsx
            </el-button
            >
          </el-upload>

          <el-button size="mini" round type="warning" @click="onDownTiMu"
          >导出题目
          </el-button
          >

        </el-col>


      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
            :data="tableData"
            :border="true"
            highlight-current-row
            stripe
            v-loading.body="isLoading"
            :resizable="true"
            :height="getH()"
        >
          <el-table-column
              v-for="(item, index) in propList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : 100"
              stripe
              show-overflow-tooltip
          >
            <!--显示图片-->
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>
              <div v-else-if="item.type == 'arrayObj'">
                {{ getOptionVal(item.data, scope.row[item.prop], item.type) }}
              </div>
              <div v-else>
                {{ scope.row[scope.column["property"]] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" size="small" @click="onEdit(scope.row)"
                >编辑
                </el-button
                >
                <el-button type="text" size="small" @click="onDel(scope.row)">
                  删除
                </el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="!editItem.id ? '新增题目' : '编辑题目'"
        :visible.sync="showEditDlg"
        width="300"
        :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
              class="myElCol"
              :span="11"
              v-for="(item, index) in propList"
              :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                  v-if="item.type == 'array'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>


              <el-select
                  v-else-if="item.type == 'arrayObj'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2.title"
                    :value="item2.val"
                >
                </el-option>
              </el-select>


              <el-date-picker
                  v-else-if="item.type == 'time'"
                  v-model="editItem[item.prop]"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                  v-else
                  :type="item.type == 'textarea' ? 'textarea' : 'text'"
                  :disabled="item.prop == 'id'"
                  :rows="2"
                  clearable
                  v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>

import fa from "element-ui/src/locale/lang/fa";

export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      tm_type: "",
      searchKey: "",
      shijuan_id: "",
      validArr: ['无',"单选", "多选"],
      //用户数据列表 默认为空
      tableData: [],
      isLoading: false,
      /*zy_kaoshi_timu`  (
  `id` int(11) NOT NULL AUTO_INCREMENT COMMENT '题目ID',
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
  `shijuan_id` int(11) NOT NULL DEFAULT 0 COMMENT '试卷ID',
  `order_id` int(11) NOT NULL DEFAULT 0 COMMENT '排序ID',
  `tm_type` int(11) NOT NULL DEFAULT 1 COMMENT '类型(1-单选 2-多选)',
  `tm_question` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '问题',
  `tm_option1` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '选项1',
  `tm_option2` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '选项2',
  `tm_option3` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '选项3',
  `tm_option4` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '选项4',
  `tm_option5` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '选项5',
  `tm_answer` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '答案ABCDE(多选时#分隔)',
  `tm_score` double(11, 1) NOT NULL DEFAULT 0.0 COMMENT '分值',
`tm_msg` text COMMENT '题目解析',
  `bz` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '备注',
  PRIMARY KEY (`id`) USING BTREE,
       * 
       */
      propList: [
        {
          prop: "id",
          label: "题目ID",
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 200,
          type: "time",
        },
        {
          prop: "shijuan_id",
          label: "试卷ID",
        },

        {
          prop: "order_id",
          label: "排序从小到大",
          width: 150,
        },
        {
          prop: "tm_type",
          label: "题目类型",
          type: "arrayObj",
          data: [{
            title: "单选",
            val: 1,
          },
            {
              title: "多选",
              val: 2,
            }
          ],
        },
        {
          prop: "tm_question",
          label: "问题",
          width: 300,
        },
        {
          prop: "tm_option1",
          label: "选项1",
          width: 150,
        },
        {
          prop: "tm_option2",
          label: "选项2",
          width: 150,
        },
        {
          prop: "tm_option3",
          label: "选项3",
          width: 150,
        },
        {
          prop: "tm_option4",
          label: "选项4",
          width: 150,
        },
        {
          prop: "tm_option5",
          label: "选项5",
          width: 150,
        },
        {
          prop: "tm_answer",
          label: "答案ABCDE(#分隔)",
          width: 220,
        },
        {
          prop: "tm_score",
          label: "分值",
        },
        {
          prop: "tm_msg",
          label: "解析",
          type: 'textarea',
          width: 350,
        },
        {
          prop: "bz",
          label: "备注",
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    console.log("KaoShiLog 创建");
    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 350;
    },
    //监听对话框
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    //获取用户参数列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        searchKey: this.searchKey,
        tm_type: this.tm_type,
        shijuan_id: this.shijuan_id,
      };
      const res = await this.$post(`KaoShiTiMu/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;
      /*  for (let i in this.tableData) {
        if (this.tableData[i]["user_log"].length > 0) {
          this.tableData[i]["user_log_val"] =
            this.$imgUrl + this.tableData[i]["user_log"];
        } else {
          this.tableData[i]["user_log_val"] = "";
        }
      }*/
      // console.log(this.tableData,res.data.data)
      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEdit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let Obj1 = {
        id: this.editItem.id,
        data: this.editItem,
      };
      const res = await this.$post(`KaoShiTiMu/saveData`, Obj1);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDel(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
          "此操作将永久删除该后台用户信息?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        id: item.id,
      };
      console.log(obj2);
      const res = await this.$post(`KaoShiTiMu/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    async onWrite() {
      const res = await this.$post(`KaoShiTiMu/upSumNum`);
      console.log("onWrite", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
    },
    //上传题目
    onUpTiMu() {

    },
    //下载题目
    async onDownTiMu() {
      this.isLoading = true
      const {code, msg, data} = await this.$post(`KaoShiTiMu/downTiMu`);
      this.isLoading = false
      if (code !== 200) return;

      console.log("下载返回", msg);


      if (code != 200) return

      let url = this.$imgUrl + data
      window.open(url)
    },
    //文件超过数量
    onExceed() {
      this.$err('文件不能超过1个')
    },
    //文件上传成功
    onSuccess(res, file, fileList) {
      console.log(res)
      this.isUploading = false
      this.$refs['refupload'].clearFiles()
      this.isLoading = false
      //210203新增失败提示
      if (res.code !== 200) return this.$err(res.msg)

      this.$ok(res.msg)
      this.getList()
    },
    //文件上传失败
    onError(err, file, fileList) {
      this.$err(err)
      this.isLoading = false
      this.$refs['refupload'].clearFiles()
    },
    //开始上传前
    beforeUpload(file) {
      this.isLoading = true
    },
    //文件改变
    onChange(file, fileList) {
      console.log(file)
      console.log(fileList)
    },
    //下载导入模板
    onDownMuoBan() {
      let url = this.$imgUrl + "storage/xlsx/导入题目模板.xlsx"
      window.open(url)
    }
  },
};
</script>

<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
